function SkillsSection() {
    const skills = {
      frontend: [
        { name: 'HTML5', icon: '🌐' },
        { name: 'CSS3', icon: '🎨' },
        { name: 'React.js', icon: '⚛️' },
        { name: 'Tailwind CSS', icon: '🎯' }
      ],
      backend: [
        { name: 'Node.js', icon: '🟢' },
        { name: 'MongoDB', icon: '🍃' }
      ],
      tools: [
        { name: 'Git', icon: '📚' },
        { name: 'Docker', icon: '🐳' },
        { name: 'VS Code', icon: '💻' },
        { name: 'Linux', icon: '🐧' }
      ]
    };
  
    return (
      <section className="py-20 px-4 bg-gray-50" id="skills">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Skills & Technologies
            </h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto rounded-full mb-6"></div>
            <p className="text-gray-600 max-w-3xl mx-auto">
              My technical skills and tools I use daily
            </p>
          </div>
  
          <div className="grid md:grid-cols-3 gap-8">
            {/* Frontend */}
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <h3 className="text-xl font-semibold mb-4 text-gray-800">Frontend Development</h3>
              <div className="grid grid-cols-2 gap-4">
                {skills.frontend.map((skill) => (
                  <div 
                    key={skill.name}
                    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <span className="text-xl">{skill.icon}</span>
                    <span className="text-gray-600">{skill.name}</span>
                  </div>
                ))}
              </div>
            </div>
  
            {/* Backend */}
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <h3 className="text-xl font-semibold mb-4 text-gray-800">Backend Development</h3>
              <div className="grid grid-cols-2 gap-4">
                {skills.backend.map((skill) => (
                  <div 
                    key={skill.name}
                    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <span className="text-xl">{skill.icon}</span>
                    <span className="text-gray-600">{skill.name}</span>
                  </div>
                ))}
              </div>
            </div>
  
            {/* Tools */}
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <h3 className="text-xl font-semibold mb-4 text-gray-800">Tools & Others</h3>
              <div className="grid grid-cols-2 gap-4">
                {skills.tools.map((skill) => (
                  <div 
                    key={skill.name}
                    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <span className="text-xl">{skill.icon}</span>
                    <span className="text-gray-600">{skill.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  export default SkillsSection;
function AboutSection() {
    return (
      <section className="py-20 px-4 bg-white" id="about">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              About Me
            </h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto rounded-full mb-6"></div>
            <p className="text-gray-600 max-w-3xl mx-auto">
              Get to know me better
            </p>
          </div>
  
          <div className="max-w-3xl mx-auto">
            <div className="space-y-6">
              <h3 className="text-2xl font-semibold text-gray-800">
              A passionate Developer from Serang, Indonesia. 🌟
              </h3>
              <div className="space-y-6 text-base md:text-lg text-gray-600 max-w-3xl mx-auto leading-relaxed">
  {/* Introduction */}
  <p>
    Halo! Saya adalah salah satu Siswa yang bersekolah di SMK Negeri 2 Depok-Sleman, 
    Jurusan SIJA (Sistem Informasi Jaringan Dan Aplikasi).
  </p>

  {/* Journey Story */}
  <p>
    Ketertarikan saya pada dunia IT bermula ketika saya melihat seseorang membuat 
    Kecerdasan Buatan di WhatsApp atau biasa disebut dengan Bot, dan karena itu pun 
    saya turut tertarik dengan Bahasa Mesin lainnya.
  </p>

  {/* Technical Journey */}
  <p>
    Dalam perjalanan pembelajaran saya, saya telah mengeksplorasi berbagai bahasa pemrograman seperti JavaScript, Python, dan PHP. Saya memiliki passion khusus dalam 
    pengembangan web dan automasi.
  </p>

  {/* Current Focus */}
  <div>
    <p className="mb-3">Saat ini, saya fokus mengembangkan keterampilan dalam:</p>
    <ul className="list-disc list-inside space-y-2 pl-4">
      <li>Full-stack Web Development</li>
      <li>Bot Development</li>
      <li>Network Administration</li>
    </ul>
  </div>

  {/* Vision */}
  <p>
    Saya percaya bahwa teknologi dapat membuat perubahan positif dalam kehidupan 
    sehari-hari, dan saya berkomitmen untuk terus belajar dan berkembang dalam 
    bidang IT.
  </p>

  {/* Community */}
  <p>
    Di luar kegiatan coding, saya juga aktif dalam komunitas programming dan senang 
    berbagi pengetahuan dengan sesama developer.
  </p>

  {/* Call to Action */}
  <p className="font-medium text-blue-600">
    Mari berkolaborasi dan ciptakan sesuatu yang luar biasa bersama!
  </p>
</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  export default AboutSection;
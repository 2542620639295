import { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { db } from '../../firebase/config';

function LoadingDots() {
  return (
    <div className="flex items-center justify-center space-x-2">
      <div className="w-3 h-3 bg-blue-600 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
      <div className="w-3 h-3 bg-blue-600 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
      <div className="w-3 h-3 bg-blue-600 rounded-full animate-bounce"></div>
    </div>
  );
}

function ResumeSection() {
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadResume = async () => {
      try {
        const resumeRef = ref(db, 'resume');
        const snapshot = await get(resumeRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const formattedData = {
            education: Object.values(data.education || {}),
            experience: Object.values(data.experience || {})
          };
          setResume(formattedData);
        }
      } catch (error) {
        console.error('Error loading resume:', error);
      } finally {
        setLoading(false);
      }
    };

    loadResume();
  }, []);

  if (loading) {
    return (
      <section id="resume" className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Experience & Education</h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto rounded-full"></div>
          </div>
          <div className="flex justify-center items-center min-h-[200px]">
            <LoadingDots />
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="resume" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Experience & Education</h2>
          <div className="w-20 h-1 bg-blue-600 mx-auto rounded-full mb-6"></div>
        </div>

        <div className="grid md:grid-cols-2 gap-12">
          {/* Experience Column */}
          <div>
            <h3 className="text-2xl font-semibold mb-8 text-gray-800 flex items-center">
              <svg className="w-6 h-6 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              Experience
            </h3>
            <div className="space-y-6">
              {resume?.experience?.length > 0 ? (
                resume.experience.map((exp, index) => (
                  <div key={index} className="relative pl-8 before:absolute before:left-0 before:top-0 before:bottom-0 before:w-0.5 before:bg-blue-600">
                    <div className="absolute left-0 top-1.5 w-2 h-2 rounded-full bg-blue-600 -translate-x-[5px]"></div>
                    <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                      <div className="flex justify-between items-start mb-2">
                        <h4 className="text-lg font-semibold text-gray-800">{exp.jobTitle}</h4>
                        <span className="text-sm text-gray-500">{exp.period}</span>
                      </div>
                      <p className="text-blue-600 mb-2">{exp.company}</p>
                      <p className="text-gray-600">{exp.description}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No experience data available</p>
              )}
            </div>
          </div>

          {/* Education Column */}
          <div>
            <h3 className="text-2xl font-semibold mb-8 text-gray-800 flex items-center">
              <svg className="w-6 h-6 mr-2 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l9-5-9-5-9 5 9 5z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l9-5-9-5-9 5 9 5z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l9-5-9-5-9 5 9 5z" />
              </svg>
              Education
            </h3>
            <div className="space-y-6">
              {resume?.education?.length > 0 ? (
                resume.education.map((edu, index) => (
                  <div key={index} className="relative pl-8 before:absolute before:left-0 before:top-0 before:bottom-0 before:w-0.5 before:bg-blue-600">
                    <div className="absolute left-0 top-1.5 w-2 h-2 rounded-full bg-blue-600 -translate-x-[5px]"></div>
                    <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                      <div className="flex justify-between items-start mb-2">
                        <h4 className="text-lg font-semibold text-gray-800">{edu.degree}</h4>
                        <span className="text-sm text-gray-500">{edu.period}</span>
                      </div>
                      <p className="text-blue-600 mb-2">{edu.school}</p>
                      <p className="text-gray-600">{edu.description}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No education data available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResumeSection;
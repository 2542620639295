import { useState, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { ref, push, remove, get } from 'firebase/database';
import { auth, db } from '../../firebase/config';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import toast, { Toaster } from 'react-hot-toast';

function Dashboard() {
  const navigate = useNavigate();
  const [activeForm, setActiveForm] = useState(null);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [resumeItems, setResumeItems] = useState({
    education: [],
    experience: []
  });

  // Portfolio Form State
  const [portfolioData, setPortfolioData] = useState({
    title: '',
    description: '',
    imageUrl: '',
    technologies: '',
    liveUrl: '',
    githubUrl: ''
  });

  // Resume Form State
  const [resumeData, setResumeData] = useState({
    type: 'education',
    title: '',
    organization: '',
    period: '',
    description: ''
  });

  useEffect(() => {
    fetchPortfolioItems();
    fetchResumeItems();
  }, []);

  const fetchPortfolioItems = async () => {
    try {
      const portfolioRef = ref(db, 'portfolio');
      const snapshot = await get(portfolioRef);
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((child) => {
          items.push({
            id: child.key,
            ...child.val()
          });
        });
        setPortfolioItems(items);
      }
    } catch (error) {
      console.error('Error fetching portfolio items:', error);
      toast.error('Failed to fetch portfolio items');
    }
  };

  const fetchResumeItems = async () => {
    try {
      const educationRef = ref(db, 'resume/education');
      const experienceRef = ref(db, 'resume/experience');
      
      const [educationSnap, experienceSnap] = await Promise.all([
        get(educationRef),
        get(experienceRef)
      ]);

      const education = [];
      const experience = [];

      if (educationSnap.exists()) {
        educationSnap.forEach((child) => {
          education.push({
            id: child.key,
            ...child.val()
          });
        });
      }

      if (experienceSnap.exists()) {
        experienceSnap.forEach((child) => {
          experience.push({
            id: child.key,
            ...child.val()
          });
        });
      }

      setResumeItems({
        education,
        experience
      });
    } catch (error) {
      console.error('Error fetching resume items:', error);
      toast.error('Failed to fetch resume items');
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/admin/login');
      toast.success('Logged out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Failed to log out');
    }
  };

  const handlePortfolioSubmit = async (e) => {
    e.preventDefault();
    const loadingToast = toast.loading('Adding project...');
    try {
      const portfolioRef = ref(db, 'portfolio');
      await push(portfolioRef, portfolioData);
      setPortfolioData({
        title: '',
        description: '',
        imageUrl: '',
        technologies: '',
        liveUrl: '',
        githubUrl: ''
      });
      toast.success('Project added successfully!', { id: loadingToast });
      setActiveForm(null);
      fetchPortfolioItems();
    } catch (error) {
      console.error('Error adding project:', error);
      toast.error('Failed to add project', { id: loadingToast });
    }
  };

  const handleResumeSubmit = async (e) => {
    e.preventDefault();
    const loadingToast = toast.loading('Adding entry...');
    try {
      const resumeRef = ref(db, `resume/${resumeData.type}`);
      await push(resumeRef, {
        [resumeData.type === 'education' ? 'degree' : 'jobTitle']: resumeData.title,
        [resumeData.type === 'education' ? 'school' : 'company']: resumeData.organization,
        period: resumeData.period,
        description: resumeData.description
      });
      setResumeData({
        type: 'education',
        title: '',
        organization: '',
        period: '',
        description: ''
      });
      toast.success('Entry added successfully!', { id: loadingToast });
      setActiveForm(null);
      fetchResumeItems();
    } catch (error) {
      console.error('Error adding entry:', error);
      toast.error('Failed to add entry', { id: loadingToast });
    }
  };

  const handleDeletePortfolio = async (id) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      const loadingToast = toast.loading('Deleting project...');
      try {
        const itemRef = ref(db, `portfolio/${id}`);
        await remove(itemRef);
        setPortfolioItems(portfolioItems.filter(item => item.id !== id));
        toast.success('Project deleted successfully!', { id: loadingToast });
      } catch (error) {
        console.error('Error deleting project:', error);
        toast.error('Failed to delete project', { id: loadingToast });
      }
    }
  };

  const handleDeleteResume = async (type, id) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      const loadingToast = toast.loading('Deleting entry...');
      try {
        const itemRef = ref(db, `resume/${type}/${id}`);
        await remove(itemRef);
        setResumeItems(prev => ({
          ...prev,
          [type]: prev[type].filter(item => item.id !== id)
        }));
        toast.success('Entry deleted successfully!', { id: loadingToast });
      } catch (error) {
        console.error('Error deleting entry:', error);
        toast.error('Failed to delete entry', { id: loadingToast });
      }
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <Toaster position="top-right" />
      
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Admin Dashboard</h1>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleLogout}
          className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
        >
          Logout
        </motion.button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Portfolio Card */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white shadow rounded-lg p-6"
        >
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold">Portfolio Projects</h2>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveForm(activeForm === 'portfolio' ? null : 'portfolio')}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              {activeForm === 'portfolio' ? 'Cancel' : 'Add Project'}
            </motion.button>
          </div>

          <AnimatePresence mode="wait">
            {activeForm === 'portfolio' ? (
              <motion.form
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
                onSubmit={handlePortfolioSubmit}
                className="space-y-4"
              >
                <input
                  type="text"
                  placeholder="Project Title"
                  value={portfolioData.title}
                  onChange={(e) => setPortfolioData({...portfolioData, title: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
                <textarea
                  placeholder="Description"
                  value={portfolioData.description}
                  onChange={(e) => setPortfolioData({...portfolioData, description: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                  rows="3"
                  required
                />
                <input
                  type="url"
                  placeholder="Image URL"
                  value={portfolioData.imageUrl}
                  onChange={(e) => setPortfolioData({...portfolioData, imageUrl: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                />
                <input
                  type="text"
                  placeholder="Technologies (comma-separated)"
                  value={portfolioData.technologies}
                  onChange={(e) => setPortfolioData({...portfolioData, technologies: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                />
                <input
                  type="url"
                  placeholder="Live URL"
                  value={portfolioData.liveUrl}
                  onChange={(e) => setPortfolioData({...portfolioData, liveUrl: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                />
                <input
                  type="url"
                  placeholder="GitHub URL"
                  value={portfolioData.githubUrl}
                  onChange={(e) => setPortfolioData({...portfolioData, githubUrl: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                />
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700"
                >
                  Save Project
                </motion.button>
              </motion.form>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="space-y-4"
              >
                <AnimatePresence>
                  {portfolioItems.map((item) => (
                    <motion.div
                      key={item.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      className="border rounded-lg p-4"
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="font-semibold">{item.title}</h3>
                          <p className="text-sm text-gray-600">{item.description}</p>
                        </div>
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => handleDeletePortfolio(item.id)}
                          className="text-red-600 hover:text-red-800"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </motion.button>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {/* Resume Card */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white shadow rounded-lg p-6"
        >
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold">Resume</h2>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveForm(activeForm === 'resume' ? null : 'resume')}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              {activeForm === 'resume' ? 'Cancel' : 'Add Entry'}
            </motion.button>
          </div>

          <AnimatePresence mode="wait">
            {activeForm === 'resume' ? (
              <motion.form
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
                onSubmit={handleResumeSubmit}
                className="space-y-4"
              >
                <select
                  value={resumeData.type}
                  onChange={(e) => setResumeData({...resumeData, type: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                >
                  <option value="education">Education</option>
                  <option value="experience">Experience</option>
                </select>
                <input
                  type="text"
                  placeholder={resumeData.type === 'education' ? 'Degree/Course' : 'Job Title'}
                  value={resumeData.title}
                  onChange={(e) => setResumeData({...resumeData, title: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
                <input
                  type="text"
                  placeholder={resumeData.type === 'education' ? 'School/Institution' : 'Company'}
                  value={resumeData.organization}
                  onChange={(e) => setResumeData({...resumeData, organization: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
                <input
                  type="text"
                  placeholder="Period (e.g., 2020 - 2024)"
                  value={resumeData.period}
                  onChange={(e) => setResumeData({...resumeData, period: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
                <textarea
                  placeholder="Description"
                  value={resumeData.description}
                  onChange={(e) => setResumeData({...resumeData, description: e.target.value})}
                  className="w-full px-3 py-2 border rounded-md"
                  rows="3"
                  required
                />
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700"
                >
                  Save Entry
                </motion.button>
              </motion.form>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="space-y-6"
              >
                <div>
                  <h3 className="font-semibold mb-3">Education</h3>
                  <AnimatePresence>
                    {resumeItems.education.map((item) => (
                      <motion.div
                        key={item.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        className="border rounded-lg p-4 mb-4"
                      >
                        <div className="flex justify-between items-start">
                          <div>
                            <h4 className="font-medium">{item.degree}</h4>
                            <p className="text-sm text-gray-600">{item.school}</p>
                            <p className="text-sm text-gray-500">{item.period}</p>
                            <p className="text-sm text-gray-600 mt-1">{item.description}</p>
                          </div>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleDeleteResume('education', item.id)}
                            className="text-red-600 hover:text-red-800"
                          >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                          </motion.button>
                        </div>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>

                <div>
                  <h3 className="font-semibold mb-3">Experience</h3>
                  <AnimatePresence>
                    {resumeItems.experience.map((item) => (
                      <motion.div
                        key={item.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        className="border rounded-lg p-4 mb-4"
                      >
                        <div className="flex justify-between items-start">
                          <div>
                            <h4 className="font-medium">{item.jobTitle}</h4>
                            <p className="text-sm text-gray-600">{item.company}</p>
                            <p className="text-sm text-gray-500">{item.period}</p>
                            <p className="text-sm text-gray-600 mt-1">{item.description}</p>
                          </div>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleDeleteResume('experience', item.id)}
                            className="text-red-600 hover:text-red-800"
                          >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                          </motion.button>
                        </div>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
}

export default Dashboard;
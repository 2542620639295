function About() {
    return (
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">About Me</h1>
            <div className="prose max-w-none">
              <p className="text-gray-600">
                Hi! I'm a passionate software developer with experience in web development
                and a strong interest in creating user-friendly applications.
              </p>
              
              <h2 className="text-xl font-semibold text-gray-900 mt-6 mb-3">Skills</h2>
              <ul className="list-disc list-inside text-gray-600">
                <li>Frontend Development (React, HTML, CSS, JavaScript)</li>
                <li>Backend Development (Node.js, Express)</li>
                <li>Database Management (MongoDB, MySQL)</li>
                <li>Version Control (Git)</li>
                <li>UI/UX Design</li>
              </ul>
  
              <h2 className="text-xl font-semibold text-gray-900 mt-6 mb-3">Experience</h2>
              <p className="text-gray-600">
                I have worked on various projects, from small business websites to
                large-scale applications. My focus is on creating clean, efficient,
                and maintainable code while ensuring a great user experience.
              </p>
  
              <h2 className="text-xl font-semibold text-gray-900 mt-6 mb-3">Education</h2>
              <p className="text-gray-600">
                Bachelor's Degree in Computer Science
                <br />
                Relevant coursework in software engineering, algorithms, and web development
              </p>
  
              <h2 className="text-xl font-semibold text-gray-900 mt-6 mb-3">Interests</h2>
              <p className="text-gray-600">
                When I'm not coding, I enjoy reading tech blogs, contributing to open-source
                projects, and staying up-to-date with the latest web development trends.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default About; 
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="relative bg-gradient-to-b from-gray-900 to-black text-gray-300 overflow-hidden">
      {/* Pixel Walking Animation */}
      <div className="absolute bottom-0 left-0 w-full h-16 overflow-hidden">
        <div className="absolute bottom-0 animate-pixel-walk">
          {/* Pixel Character */}
          <div className="w-8 h-8 relative">
            {/* Head */}
            <div className="absolute w-4 h-4 bg-white/80 rounded-sm top-0 left-2"></div>
            {/* Body */}
            <div className="absolute w-4 h-3 bg-blue-500/80 top-4 left-2"></div>
            {/* Legs */}
            <div className="absolute w-2 h-3 bg-gray-700/80 top-7 left-2 animate-leg-left"></div>
            <div className="absolute w-2 h-3 bg-gray-700/80 top-7 left-4 animate-leg-right"></div>
            {/* Arms */}
            <div className="absolute w-2 h-2 bg-blue-500/80 top-4 left-0 animate-arm-left"></div>
            <div className="absolute w-2 h-2 bg-blue-500/80 top-4 left-6 animate-arm-right"></div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 py-12">
        <div className="text-center">
          <Link 
            to="/" 
            className="inline-block text-2xl font-bold bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text hover:scale-105 transition-transform duration-300"
          >
            Muhammad Akbar
          </Link>
          <p className="mt-4 text-sm text-gray-400">
            © {new Date().getFullYear()} All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
import AboutSection from "../components/sections/AboutSection";
import SkillsSection from "../components/sections/SkillsSection";
import ResumeSection from "../components/sections/ResumesSection";
import PortfolioSection from "../components/sections/PortfolioSection";

function Home() {
    return (
      <div className="min-h-screen">
        {/* Hero Section */}
        <section className="pt-24 pb-16 px-4 bg-gradient-to-b from-white to-gray-50">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-8">
              <div className="mb-6 relative">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600/20 to-purple-600/20 rounded-full blur-xl"></div>
                <img 
                  src="https://avatars.githubusercontent.com/u/110098168?v=4" 
                  alt="Profile" 
                  className="relative w-24 h-24 md:w-32 md:h-32 rounded-full mx-auto ring-4 ring-white shadow-xl"
                />
              </div>
              <span className="text-sm md:text-base text-blue-600 font-medium mb-4 block">
                👋 Hello, I'm Muhammad Akbar Amaanullaah
              </span>
              <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold mb-4">
                <span className="bg-gradient-to-r from-blue-600 to-purple-600 text-transparent bg-clip-text leading-relaxed">
                  Frontend Developer
                </span>
                <br />
                <span className="text-2xl md:text-4xl lg:text-5xl text-gray-800">
                  & Backend Engineer
                </span>
              </h1>
              <p className="text-base md:text-xl text-gray-600 mb-8 max-w-2xl mx-auto leading-relaxed">
                Passionate about creating beautiful and functional web and Bot experiences.
              </p>
              <div className="flex flex-col sm:flex-row justify-center gap-4 sm:space-x-4">
                <a 
                  href="#projects" 
                  className="px-6 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full hover:opacity-90 transition-opacity flex items-center justify-center space-x-2 shadow-lg shadow-blue-500/25 hover:scale-105 duration-300"
                >
                  <span>View Projects</span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </a>
                
                {/* <a 
                  href="#" 
                  className="px-6 py-3 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-full hover:opacity-90 transition-opacity flex items-center justify-center space-x-2 shadow-lg shadow-purple-500/25 hover:scale-105 duration-300"
                >
                  <span>View Blogs</span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9.5a2.5 2.5 0 00-2.5-2.5H15" />
                  </svg>
                </a> */}

                <a 
                  href="mailto:20426@.student.stembayo.sch.id" 
                  className="px-6 py-3 border border-gray-300 rounded-full hover:border-blue-600 hover:text-blue-600 transition-colors flex items-center justify-center space-x-2 bg-white/50 backdrop-blur-sm hover:scale-105 duration-300"
                >
                  <span>Contact Me</span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </a>
              </div>
              
              {/* Social Links */}
              <div className="mt-8 flex justify-center space-x-6">
                <a 
                  href="https://github.com/ZhenDevs" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="group relative"
                >
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-600 to-purple-600 rounded-full opacity-0 group-hover:opacity-100 transition duration-300 blur"></div>
                  <div className="relative flex items-center justify-center w-12 h-12 bg-white rounded-full hover:bg-gray-50 transition duration-300">
                    <svg className="w-6 h-6 text-gray-600 group-hover:text-blue-600 transition duration-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                    </svg>
                  </div>
                </a>

                <a 
    href="https://www.instagram.com/oiibarrr?igsh=MWk2cXE3b3JieDd4ZQ==" 
    target="_blank" 
    rel="noopener noreferrer" 
    className="group relative"
  >
    <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-600 to-purple-600 rounded-full opacity-0 group-hover:opacity-100 transition duration-300 blur"></div>
    <div className="relative flex items-center justify-center w-12 h-12 bg-white rounded-full hover:bg-gray-50 transition duration-300">
      <svg className="w-6 h-6 text-gray-600 group-hover:text-blue-600 transition duration-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
      </svg>
    </div>
  </a>
              </div>
              {/* Scroll Down Indicator */}
<div className="mt-12 animate-bounce flex justify-center">
  <svg 
    className="w-6 h-6 text-gray-400"
    fill="none" 
    stroke="currentColor" 
    viewBox="0 0 24 24"
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={2} 
      d="M19 14l-7 7m0 0l-7-7m7 7V3"
    />
  </svg>
</div>
            </div>
          </div>
        </section>
  
        <AboutSection />
        <SkillsSection />
        <ResumeSection />
        <PortfolioSection />
      </div>
    );
}
  
export default Home;

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,        
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,                
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL, 
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,                                  
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,                            
  appId: process.env.REACT_APP_FIREBASE_APP_ID                     
};


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getDatabase(app);

export default app;
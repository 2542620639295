function NetworkLoader() {
    return (
      <div className="loader-container">
        <div className="network-loader">
          <div className="node node-1"></div>
          <div className="node node-2"></div>
          <div className="node node-3"></div>
          <div className="node node-4"></div>
          <div className="node node-5"></div>
          <div className="node node-6"></div>
          <div className="line line-1"></div>
          <div className="line line-2"></div>
          <div className="line line-3"></div>
          <div className="line line-4"></div>
          <div className="line line-5"></div>
        </div>
      </div>
    );
  }
  
  export default NetworkLoader;
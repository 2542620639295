import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/config';
import Home from './pages/Home';
import About from './pages/About';
import Login from './components/admin/Login';
import Dashboard from './components/admin/Dashboard';
import Footer from './components/Footer';
import NetworkLoader from './components/NetworkLoader';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <NetworkLoader />;
  }

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route 
              path="/admin/login" 
              element={user ? <Navigate to="/admin/dashboard" /> : <Login />} 
            />
            <Route
              path="/admin/dashboard"
              element={user ? <Dashboard /> : <Navigate to="/admin/login" />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;